/*
 * Component: Social Widgets
 * -------------------------
 */
//General widget style
.box-widget {
  border: none;
  position: relative;
}

//User Widget Style 1
.widget-user {
  //User name container
  .widget-user-header {
    padding: 20px;
    height: 120px;
    .border-top-radius(@box-border-radius);
  }
  //User name
  .widget-user-username {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 300;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  }
  //User single line description
  .widget-user-desc {
    margin-top: 0;
  }
  //User image container
  .widget-user-image {
    position: absolute;
    top: 65px;
    left: 50%;
    margin-left: -45px;
    > img {
      width: 90px;
      height: auto;
      border: 3px solid #fff;
    }
  }
  .box-footer {
    padding-top: 30px;
  }
}

//User Widget Style 2
.widget-user-2 {
  //User name container
  .widget-user-header {
    padding: 20px;
    .border-top-radius(@box-border-radius);
  }
  //User name
  .widget-user-username {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 300;
  }
  //User single line description
  .widget-user-desc {
    margin-top: 0;
  }
  .widget-user-username,
  .widget-user-desc {
    margin-left: 75px;
  }
  //User image container
  .widget-user-image {
    > img {
      width: 65px;
      height: auto;
      float: left;
    }
  }
}
