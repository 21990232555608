/*
 * Component: Progress Bar
 * -----------------------
 */

//General CSS
.progress,
.progress > .progress-bar {
  .box-shadow(none);
  &, .progress-bar {
    .border-radius(@progress-bar-border-radius);
  }
}

/* size variation */
.progress.sm,
.progress-sm {
  height: 10px;
  &, .progress-bar {
    .border-radius(@progress-bar-sm-border-radius);
  }
}

.progress.xs,
.progress-xs {
  height: 7px;
  &, .progress-bar {
    .border-radius(@progress-bar-xs-border-radius);
  }
}

.progress.xxs,
.progress-xxs {
  height: 3px;
  &, .progress-bar {
    .border-radius(@progress-bar-xs-border-radius);
  }
}

/* Vertical bars */
.progress.vertical {
  position: relative;
  width: 30px;
  height: 200px;
  display: inline-block;
  margin-right: 10px;
  > .progress-bar {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  //Sizes
  &.sm,
  &.progress-sm {
    width: 20px;
  }

  &.xs,
  &.progress-xs {
    width: 10px;
  }
  &.xxs,
  &.progress-xxs {
    width: 3px;
  }
}

//Progress Groups
.progress-group {
  .progress-text {
    font-weight: 600;
  }
  .progress-number {
    float: right;
  }
}

/* Remove margins from progress bars when put in a table */
.table {
  tr > td .progress {
    margin: 0;
  }
}

// Variations
// -------------------------
.progress-bar-light-blue,
.progress-bar-primary {
  .progress-bar-variant(@light-blue);
}

.progress-bar-green,
.progress-bar-success {
  .progress-bar-variant(@green);
}

.progress-bar-aqua,
.progress-bar-info {
  .progress-bar-variant(@aqua);
}

.progress-bar-yellow,
.progress-bar-warning {
  .progress-bar-variant(@yellow);
}

.progress-bar-red,
.progress-bar-danger {
  .progress-bar-variant(@red);
}
